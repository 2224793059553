<div>
    <section class="ig-dialog-content">
        <ig-field-error-summary [formModel]="formGroup"></ig-field-error-summary>
        <form [formGroup]="formGroup" (keydown)="onFormKeyDown($event)">
            <div class="ig-form-section">
                <div class=" ig-field-v-ct ig-stretch">
                    <label i18n>Tile Name</label>
                    <div class="ig-form-field">
                        <input pInputText igFormControl formControlName="name"
                               maxlength="250" type="text"/>
                    </div>
                </div>
                <div class=" ig-field-v-ct ig-stretch ig-margin-m-b">
                    <label i18n>Tile Description</label>
                    <div class="ig-form-field">
                        <textarea autoResize="autoResize" pInputTextarea
                                  cols="30" formControlName="description" maxlength="1000"
                                  rows="5"></textarea>
                    </div>
                </div>
                <!-- Icon/Image picker -->
                <div class=" ig-field-v-ct ig-stretch ig-margin-m-b">
                    <div class="radio-child ig-field-h-ct">
                        <p-radioButton inputId="iconRadio" name="imageType" formControlName="imageType" value="ICON">
                        </p-radioButton>
                        <label class="radiobutton-label" for="iconRadio" i18n>Icon</label>
                    </div>
                    <div class="radio-child ig-field-v-ct">
                        <div class="ig-form-field ig-w-c7">
                            <dqp-icon-picker formControlName="iconId"
                                             [defaultValue]="'chain'"
                                             placeholder="{{(''|IgConstants).REQUIRED}}"
                                             [styleClass]="(hasFieldRequiredError('iconId') ? ' ig-required' : '')">
                            </dqp-icon-picker>
                        </div>
                    </div>
                </div>
                <div class="ig-field-v-ct ig-stretch ig-margin-m-b">
                    <div class="radio-child ig-field-h-ct">
                        <p-radioButton inputId="imageRadio"  name="imageType" formControlName="imageType" value="IMAGE">
                        </p-radioButton>
                        <label class="radiobutton-label" i18n for="imageRadio">Image</label>
                    </div>
                    <div class="radio-child ig-stretch">
                        <dqp-image-picker #imagePicker
                                          formControlName="image"
                                          [maxSize]="1048576"
                                          i18n-message message="The recommended image height is 300px. The following formats are accepted. .GIF, .JPG, .PNG, up to a maximum file size of 1MB."
                        ></dqp-image-picker>
                        <ng-container *ngIf="hasFieldRequiredError('image')">
                            <span *ngIf="imageControl.errors.required" class="ig-required" i18n>{{(''|IgConstants).REQUIRED}}</span>
                        </ng-container>
                    </div>
                </div>
                <!-- Tile links -->
                <div>
                    <dqp-home-tile-links [tile]="tile"></dqp-home-tile-links>
                </div>

            </div>
        </form>
    </section>
<!--    <div>{{formGroup.value | json}}</div>-->
    <div class="ig-dialog-footer">
        <button igButton (click)="onCancelClick()" i18n-label label="Cancel"></button>
        <span class="spacer"></span>
        <button igButton (click)="onSaveButtonClick()" i18n-label label="Save Changes"
                [loading]="loading"
                [disabled]="formGroup?.invalid || !formGroup.dirty"
                class="ig-button-primary"></button>
    </div>
</div>
