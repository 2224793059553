<div [ngClass]="{'p-tree p-component p-component-content p-corner-all':true,'p-tree-selectable':selectionMode,
                'p-treenode-dragover':dragHover,'p-tree-loading': loading, 'p-tree-flex-scrollable': scrollHeight === 'flex',
                'ig-empty':!loading && (value == null || value.length === 0)}"
     [ngStyle]="style" [class]="styleClass"
     (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter()" (dragleave)="onDragLeave($event)">
    <div class="p-tree-loading-overlay p-component-overlay" *ngIf="loading">
                <i [class]="'p-tree-loading-icon pi-spin ' + loadingIcon"></i>
            </div>
    <div *ngIf="filter" class="p-tree-filter-container">
        <ig-search-field (textChange)="onSearchTextChange($event)"
                         [placeholder]="filterPlaceholder"></ig-search-field>

<!--        <input #filter type="text" autocomplete="off" class="p-tree-filter p-inputtext p-component" [attr.placeholder]="filterPlaceholder"-->
<!--               (keydown.enter)="$event.preventDefault()" (input)="_filter($event)">-->
<!--        <span class="p-tree-filter-icon pi pi-search"></span>-->
    </div>
    <p-scroller #scroller *ngIf="virtualScroll" [items]="serializedValue" styleClass="p-tree-wrapper" [style]="{'height': scrollHeight}" [itemSize]="virtualScrollItemSize||_virtualNodeHeight"
                [lazy]="lazy" (onScroll)="onScroll.emit($event)" (onScrollIndexChange)="onScrollIndexChange.emit($event)" (onLazyLoad)="onLazyLoad.emit($event)" [options]="virtualScrollOptions">
        <ng-template pTemplate="content" let-items let-scrollerOptions="options">
            <ul *ngIf="items" class="p-tree-container" [ngClass]="scrollerOptions.contentStyleClass" [style]="scrollerOptions.contentStyle" role="tree" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy">
                <ig-tree-node *ngFor="let rowNode of items; let firstChild=first;let lastChild=last; let index=index; trackBy: trackBy" [level]="rowNode.level"
                            [rowNode]="rowNode" [node]="rowNode.node" [firstChild]="firstChild" [lastChild]="lastChild" [index]="getIndex(scrollerOptions, index)" [itemSize]="scrollerOptions.itemSize" [indentation]="indentation"></ig-tree-node>
            </ul>
        </ng-template>
        <ng-container *ngIf="loaderTemplate">
            <ng-template pTemplate="loader" let-scrollerOptions="options">
                <ng-container *ngTemplateOutlet="loaderTemplate; context: {options: scrollerOptions}"></ng-container>
            </ng-template>
        </ng-container>
    </p-scroller>
    <ng-container *ngIf="!virtualScroll">
        <div class="p-tree-wrapper" [style.max-height]="scrollHeight" (scroll)="onTreeContainerScroll()">
            <ul class="p-tree-container" (scroll)="onTreeContainerScroll()" *ngIf="getRootNode()" role="tree" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy">
                <ig-tree-node [attr.data-node-id]="node?.key" *ngFor="let node of getRootNode(); let firstChild=first;let lastChild=last; let index=index; trackBy: trackBy" [node]="node"
                              [firstChild]="firstChild" [lastChild]="lastChild" [index]="index" [level]="0"></ig-tree-node>
            </ul>
        </div>
    </ng-container>
    <div class="p-tree-empty-message" *ngIf="!loading && (value == null || value.length === 0)">{{emptyMessage}}</div>
</div>
<!--<div [ngClass]="{'p-tree p-tree-horizontal p-component':true,'p-tree-selectable':selectionMode}"  [ngStyle]="style" [class]="styleClass" *ngIf="horizontal">-->
<!--    <div class="p-tree-loading-mask p-component-overlay" *ngIf="loading">-->
<!--                <i [class]="'p-tree-loading-icon pi-spin ' + loadingIcon"></i>-->
<!--    </div>-->
<!--    <table *ngIf="value&&value[0]">-->
<!--        <ig-tree-node [attr.data-node-id]="value[0]?.key" [node]="value[0]" [root]="true"></ig-tree-node>-->
<!--    </table>-->
<!--    <div class="p-tree-empty-message" *ngIf="!loading && (value == null || value.length === 0)">{{emptyMessage}}</div>-->
<!--</div>-->
