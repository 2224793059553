<ng-template [ngIf]="node">
    <li *ngIf="tree.droppableNodes" class="p-treenode-droppoint" [ngClass]="{'p-treenode-droppoint-active':draghoverPrev}"
        (drop)="onDropPoint($event,-1)" (dragover)="onDropPointDragOver($event)" (dragenter)="onDropPointDragEnter($event,-1)" (dragleave)="onDropPointDragLeave($event)"></li>
    <li role="treeitem" [ngClass]="['p-treenode',node.styleClass||'', isLeaf() ? 'p-treenode-leaf': '']">
        <div class="p-treenode-content" [style.paddingLeft]="(level * indentation * 12)  + 'px'" (click)="onNodeClick($event)" (contextmenu)="onNodeRightClick($event)" (touchend)="onNodeTouchEnd()"
             (drop)="onDropNode($event)" (dragover)="onDropNodeDragOver($event)" (dragenter)="onDropNodeDragEnter($event)" (dragleave)="onDropNodeDragLeave($event)"
             [draggable]="tree.draggableNodes" (dragstart)="onDragStart($event)" (dragend)="onDragStop($event)" [attr.tabindex]="0"
             [ngClass]="{'p-treenode-selectable':tree.selectionMode && node.selectable !== false,'p-treenode-dragover':draghoverNode, 'p-highlight':isSelected()}"
             (keydown)="onKeyDown($event)" [attr.aria-posinset]="this.index + 1" [attr.aria-expanded]="this.node.expanded" [attr.aria-selected]="isSelected()" [attr.aria-label]="node.label"
        >
            <span *ngIf="tree.isRowSelectionMode()"
                  [style.marginLeft]="-(level * indentation * 12) + 'px'"
                  [style.marginRight]="(level * indentation * 12) + 'px'"
            >

                <div class="p-checkbox row-check-box" [attr.aria-checked]="isSelected()">
                    <div class="p-checkbox-box p-component"
                         [ngClass]="{'p-disabled': node.selectable === false}">
                        <span class="p-checkbox-icon p-clickable pi"
                              [ngClass]="{'pi-check':isSelected(),'pi-minus':node.partialSelected}">
                        </span>
                    </div>
                </div>
            </span>

            <span *ngIf="!isLeaf()" class="p-tree-toggler pi p-unselectable-text" [ngClass]="{'pi-caret-right':!node.expanded,'pi-caret-down':node.expanded}" (click)="toggle($event)"></span
            ><div class="p-checkbox" *ngIf="tree.selectionMode == 'checkbox'" [attr.aria-checked]="isSelected()"><div class="p-checkbox-box p-component p-corner-all" [ngClass]="{'p-disabled': node.selectable === false}">
                        <span class="p-checkbox-icon p-clickable pi"
                              [ngClass]="{'pi-check':isSelected(),'pi-minus':node.partialSelected}"></span></div></div
        ><span [class]="getIcon()" [class.leaf]="isLeaf()" *ngIf="node.icon||node.expandedIcon||node.collapsedIcon"></span
        ><span #treeNodeLabel class="p-treenode-label p-corner-all"
               [ngClass]="{'p-highlight':isSelected()}">
                            <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>
                            <span *ngIf="tree.getTemplateForNode(node)">
                                <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node, nodeLabelElem:treeNodeLabelElemRef}"></ng-container>
                            </span>
                    </span>
        </div>
        <ul class="p-treenode-children" style="display: none;" *ngIf="!tree.virtualScroll && node.children && node.expanded" [style.display]="node.expanded ? 'block' : 'none'" role="group">
            <ig-tree-node [attr.data-node-id]="childNode?.key" *ngFor="let childNode of node.children;let firstChild=first;let lastChild=last; let index=index; trackBy: tree.trackBy" [node]="childNode" [parentNode]="node"
                          [firstChild]="firstChild" [lastChild]="lastChild" [index]="index" [style.height.px]="tree.virtualNodeHeight" [level]="level + 1"></ig-tree-node>
        </ul>
    </li>
    <li *ngIf="tree.droppableNodes&&lastChild" class="p-treenode-droppoint" [ngClass]="{'p-treenode-droppoint-active p-highlight':draghoverNext}"
        (drop)="onDropPoint($event,1)" (dragover)="onDropPointDragOver($event)" (dragenter)="onDropPointDragEnter($event,1)" (dragleave)="onDropPointDragLeave($event)"></li>
<!--    <table *ngIf="tree.horizontal" [class]="node.styleClass">-->
<!--        <tbody>-->
<!--        <tr>-->
<!--            <td class="p-treenode-connector" *ngIf="!root">-->
<!--                <table class="p-treenode-connector-table">-->
<!--                    <tbody>-->
<!--                    <tr>-->
<!--                        <td [ngClass]="{'p-treenode-connector-line':!firstChild}"></td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td [ngClass]="{'p-treenode-connector-line':!lastChild}"></td>-->
<!--                    </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
<!--            </td>-->
<!--            <td class="p-treenode" [ngClass]="{'p-treenode-collapsed':!node.expanded}">-->
<!--                <div class="p-treenode-content" tabindex="0"-->
<!--                     [ngClass]="{'p-treenode-selectable':tree.selectionMode,'p-highlight':isSelected()}" (click)="onNodeClick($event)" (contextmenu)="onNodeRightClick($event)"-->
<!--                     (touchend)="onNodeTouchEnd()" (keydown)="onNodeKeydown($event)">-->
<!--                                <span class="p-tree-toggler pi pi-fw" [ngClass]="{'pi-plus':!node.expanded,'pi-minus':node.expanded}" *ngIf="!isLeaf()"-->
<!--                                      (click)="toggle($event)"></span-->
<!--                                ><span [class]="getIcon()" *ngIf="node.icon||node.expandedIcon||node.collapsedIcon"></span-->
<!--                ><span class="p-treenode-label">-->
<!--                                        <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>-->
<!--                                        <span *ngIf="tree.getTemplateForNode(node)">-->
<!--                                        <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node}"></ng-container>-->
<!--                                        </span>-->
<!--                                </span>-->
<!--                </div>-->
<!--            </td>-->
<!--            <td class="p-treenode-children-container" *ngIf="node.children && node.expanded" [style.display]="node.expanded ? 'table-cell' : 'none'">-->
<!--                <div class="p-treenode-children">-->
<!--                    <ig-tree-node [attr.data-node-id]="childNode?.key" *ngFor="let childNode of node.children;let firstChild=first;let lastChild=last; trackBy: tree.trackBy" [node]="childNode"-->
<!--                                  [firstChild]="firstChild" [lastChild]="lastChild"></ig-tree-node>-->
<!--                </div>-->
<!--            </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--    </table>-->
</ng-template>