<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Yearly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Recur every</label>
                <div class="ig-field-h-ct">
                    <p-input-number [showButtons]="true" formControlName="recurEveryYears" min="1" max="100"
                                    [inputStyleClass]="this.hasFieldRequiredError('recurEveryYears') ? 'ig-required' :''"
                                    placeholder="{{(''|IgConstants).REQUIRED}}"
                    >
                    </p-input-number>
                    <label>Years</label>
                </div>
            </div>
        </div>
        <div class="ig-field-h-ct radio-group" style="margin-bottom: 32px">
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType" value="FIXED_DATE"></p-radioButton>
            <label i18n>Standard calendar day</label>
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType"
                           value="NTH_WEEK_OF_X_DAY_OF_MONTH_Y"></p-radioButton>
            <label i18n>Specific day</label>
        </div>


        <ng-container [ngSwitch]="recurrencePatternType.value">
            <div *ngSwitchCase="'FIXED_DATE'" class="ig-field-h-ct label-ct" style="margin-bottom: 32px;">
                <label i18n>Select</label>
                <div class="medium-space">
                    <p-select formControlName="monthOfYear"
                              placeholder="{{(''|IgConstants).REQUIRED}}"
                              [appendTo]="appendTo"
                              [options]="availableMonthsOptions"
                              [styleClass]="(hasFieldRequiredError('monthOfYear') ? ' ig-required' : '')">
                    </p-select>
                </div>
                <p-input-number [showButtons]="true" formControlName="dayOfMonth" min="1" [max]="maxDayOfMonth"
                                [inputStyleClass]="this.hasFieldRequiredError('dayOfMonth') ? 'ig-required' :''"
                                placeholder="{{(''|IgConstants).REQUIRED}}"></p-input-number>
            </div>
            <div *ngSwitchCase="'NTH_WEEK_OF_X_DAY_OF_MONTH_Y'" class="ig-field-h-ct label-ct"
                 style="margin-bottom: 32px;">
                <label i18n>Select</label>
                <p-select formControlName="weekOfMonth"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableWeeksOfMonthOptions"
                          [styleClass]="(hasFieldRequiredError('weekOfMonth') ? ' ig-required' : '')">
                </p-select>
                <p-select formControlName="dayOfWeek"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableDaysOfWeekOptions"
                          [styleClass]="(hasFieldRequiredError('dayOfWeek') ? ' ig-required' : '')">
                </p-select>
                <label i18n>of</label>
                <p-select formControlName="monthOfYear"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableMonthsOptions"
                          [styleClass]="(hasFieldRequiredError('monthOfYear') ? ' ig-required' : '')">
                </p-select>
            </div>
        </ng-container>

        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'"
                                          [appendTo]="appendTo"
                                          [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>