<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Monthly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct radio-group">
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType" value="DAY_X_OF_EVERY_Y_MONTHS"
                           ></p-radioButton>
            <label i18n>Standard calendar day</label>
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType"
                           value="NTH_WEEK_OF_X_DAY_OF_EVERY_Y_MONTHS"></p-radioButton>
            <label i18n>Specific day</label>
        </div>
        <ng-container [ngSwitch]="recurrencePatternType.value">
            <div *ngSwitchCase="'DAY_X_OF_EVERY_Y_MONTHS'" class="ig-field-h-ct ig-margin-l-b label-ct">
                <label i18n>Select</label>
                <p-select formControlName="dayOfMonth"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableDaysOfMonthOptions"
                          [styleClass]="(hasFieldRequiredError('dayOfMonth') ? ' ig-required' : '')">
                </p-select>
                <label i18n>of every</label>
                <p-select formControlName="recurEveryMonths"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableMonthsOptions"
                          [styleClass]="(hasFieldRequiredError('recurEveryMonths') ? ' ig-required' : '')">
                </p-select>
                <label>months</label>
            </div>
            <div *ngSwitchCase="'NTH_WEEK_OF_X_DAY_OF_EVERY_Y_MONTHS'" class="ig-field-h-ct ig-margin-l-b label-ct">
                <label i18n>Select</label>
                <p-select formControlName="weekOfMonth"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableWeeksOfMonthOptions"
                          [styleClass]="(hasFieldRequiredError('weekOfMonth') ? ' ig-required' : '')">
                </p-select>

                <p-select formControlName="dayOfWeek"
                          placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableDaysOfWeekOptions"
                          [styleClass]="(hasFieldRequiredError('dayOfWeek') ? ' ig-required' : '')">
                </p-select>

                <label i18n>of every</label>
                <p-select formControlName="recurEveryMonths"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                          [appendTo]="appendTo"
                          [options]="availableMonthsOptions"
                          [styleClass]="(hasFieldRequiredError('recurEveryMonths') ? ' ig-required' : '')">
                </p-select>
                <label>months</label>
            </div>
        </ng-container>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'"
                                          [appendTo]="appendTo"
                                          [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>