<div class="ig-form-section" [formGroup]="formGroup">
    <div class="ig-field-v-ct">
        <label i18n>Select Start Date</label>
        <div class="ig-form-field">
            <p-date-picker formControlName="startDate" [showIcon]="true"
                           [appendTo]="appendTo"
                           [inputStyleClass]="(hasFieldRequiredError('startDate') ? 'ig-required' : '')"
                           placeholder="{{(''|IgConstants).REQUIRED}}"></p-date-picker>
        </div>
    </div>
    <div class="ig-field-h-ct radio-group">
        <p-radioButton name="endByType" inputId="endDateRadio" formControlName="endByType" value="END_BY_DATE"></p-radioButton>
        <label i18n for="endDateRadio">End date</label>
        <p-radioButton name="endByType" inputId="noEndDateRadio" formControlName="endByType" value="NO_END"></p-radioButton>
        <label i18n for="noEndDateRadio">No end date</label>
        <p-radioButton name="endByType" inputId="endsAfterRadio" formControlName="endByType" value="FIXED_NUMBER_OF_OCCURRENCES"></p-radioButton>
        <label i18n for="endsAfterRadio">Ends after</label>
    </div>
    <ng-container [ngSwitch]="endByType.value">
        <div class="ig-field-v-ct" *ngSwitchCase="'END_BY_DATE'">
            <label i18n>Select End Date</label>
            <div class="ig-form-field">
                <p-date-picker formControlName="endDate" [showIcon]="true"
                               [appendTo]="appendTo"
                               [inputStyleClass]="(hasFieldRequiredError('endDate') ? 'ig-required' : '')"
                               placeholder="{{(''|IgConstants).REQUIRED}}"></p-date-picker>
            </div>
            <ng-container *ngIf="isFieldInvalid('endDate')">
                        <span *ngIf="endDate.errors?.invalidEndDate"
                              class="ig-feedback" i18n>End date must be after start date</span>
            </ng-container>
        </div>

        <div class="ig-field-h-ct" *ngSwitchCase="'FIXED_NUMBER_OF_OCCURRENCES'">
            <div class="ig-field-v-ct">
                <label i18n>Number of occurrences</label>
                <div class="ig-form-field ig-w-c12">
                    <p-input-number [showButtons]="true" formControlName="numberOfOccurrence" min="1" max="1000"
                                    placeholder="{{(''|IgConstants).REQUIRED}}"
                                    [inputStyleClass]="(hasFieldRequiredError('numberOfOccurrence') ? 'ig-required' : '')"
                    ></p-input-number>
                </div>
            </div>
            <div class="ig-field-h-ct no-label-ct">
                <label i18n>Occurrences</label>
            </div>
        </div>
    </ng-container>
<!--    {{formGroup.value | json}}-->
</div>