<!-- Popup menu for row icon -->
<p-menu #rowIconMenu [popup]="true" [model]="rowIconMenuItems"></p-menu>
<!------------------------------------------------------------------------------
   Table
------------------------------------------------------------------------------->
<p-table #table [value]="tableData" selectionMode="single"
         [(selection)]="selectedRowData" dataKey="version" [scrollable]="true">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'version'" i18n>Version
                <p-sortIcon *ngIf="hasData" [field]="'version'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'lastModifiedBy'" i18n>Changed By
                <p-sortIcon *ngIf="hasData" [field]="'lastModifiedBy'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'lastUpdateTimestamp'" i18n>Date
                <p-sortIcon *ngIf="hasData" [field]="'lastUpdateTimestamp'"></p-sortIcon>
            </th>
            <th class="col-btn" style="width: 80px;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
            <td>{{rowData.version}}</td>
            <td>{{rowData.lastModifiedBy}}</td>
            <td>{{rowData.lastUpdateTimestamp | igDate}}</td>
            <td class="col-btn">
                <a style="width:16px" (click)="onRowIconClick($event, rowData)">
                    <i class="fa fa-ellipsis-v"></i></a>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr class="empty-message"><td i18n>No change history found.</td></tr>
    </ng-template>
</p-table>