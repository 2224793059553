<!-- label placement Above or on the left -->
<div [className]="(labelPosition === 'ABOVE' ? 'ig-field-v-ct' : 'ig-field-h-ct') + ' ig-stretch'">
    <label [attr.for]="fieldName">{{ label }}</label>
    <ng-container [ngSwitch]="componentType" [formGroup]="formGroup">
        <p-checkbox *ngSwitchCase="'CHECKBOX'" [formControlName]="fieldName"
                    [id]="fieldName"></p-checkbox>
        <ng-container *ngSwitchCase="'RADIO'">
            <ng-container *ngFor="let choice of choices">
                <p-radioButton [name]="fieldName" [id]="fieldName" [formControlName]="fieldName"
                               [value]="choice.value" class="dynamic-radio">
                </p-radioButton>
                <label >{{choice.label}}</label>
            </ng-container>
        </ng-container>
        <input *ngSwitchCase="'DATE'" [formControlName]="fieldName"
               [id]="fieldName" type="date" pInputText>
        <input *ngSwitchCase="'DATETIME'" [formControlName]="fieldName"
               [id]="fieldName" type="datetime-local" pInputText>
        <input *ngSwitchCase="'TIME'" [formControlName]="fieldName"
               [id]="fieldName" type="time" pInputText>
        <ng-container *ngSwitchCase="'COMBO'">
            <p-select [id]="fieldName" [formControlName]="fieldName"
                        [options]="choices"
                        optionLabel="label" optionValue="value"
            >
            </p-select>
        </ng-container>
        <ng-container *ngSwitchCase="'TEXT'">
            <ng-container *ngIf="semanticType === 'D3S.URI.URL' || semanticType === 'URL'">
                <dqp-url-control [formControlName]="fieldName" [formGroup]="formGroup"
                                 [id]="fieldName" [readonly]="readonly">
                </dqp-url-control>
            </ng-container>
            <ng-container *ngIf="semanticType != 'D3S.URI.URL' && semanticType != 'URL'">
                <input  [formControlName]="fieldName" [id]="fieldName" type="text" pInputText>
            </ng-container>
        </ng-container>
        <textarea *ngSwitchCase="'TEXTAREA'" [formControlName]="fieldName"
                  [id]="fieldName" [rows]="rowSpan * 3" [cols]="colSpan * 15">
        </textarea>
        <dqp-decimal-numeric-control *ngSwitchCase="'NUMERIC'" [formControlName]="fieldName"
                                     [formGroup]="formGroup" [decimalDigits]="decimalDigits">
        </dqp-decimal-numeric-control>
        <input *ngSwitchCase="'EMAIL'" [formControlName]="fieldName"
               [id]="fieldName" type="email" pInputText>
        <input *ngSwitchCase="'SECURE'" [formControlName]="fieldName"
               [id]="fieldName" type="password" pInputText>
        <dqp-user-picker-control *ngSwitchCase="'USER'"
                                 [formControlName]="fieldName"
                                 [formGroup]="formGroup"
                                 [id]="fieldName">
        </dqp-user-picker-control>
        <input *ngSwitchDefault [formControlName]="fieldName"
               [id]="fieldName" type="text" pInputText>
    </ng-container>
</div>
