<div [formGroup]="formGroup">
    <div class="p-col-12 dqp-toolbar ig-margin-m-b">
        <span class="ig-grow"></span>
        <button igButton
                i18n-igTooltip igTooltip="Reset all colors to defaults"
                icon="fa-refresh"
                i18n-label label="Reset All Colors"
                (click)="onResetAllButtonClick()"
        ></button>
        <span class="link-button-separator"></span>
        <a (click)="setTabsExpanded(false)" class="link-button" i18n>Collapse All</a>
        <span class="link-button-separator"></span>
        <a (click)="setTabsExpanded(true)" class="link-button" i18n>Expand All</a>
    </div>
    <p-accordion #accordion class="ig-padding-s-t" [multiple]="true" [value]="['general']">
        @for (next of COLOR_ITEMS; track next.groupId) {
            <p-accordion-panel [value]="next.groupId">
                <p-accordion-header>
                    <span class="p-accordion-header-text">{{ next.group }}</span>
                </p-accordion-header>
                <p-accordion-content>
                    @for (nextColorItem of next.items; track nextColorItem.controlName) {
                        <div class="ig-field-v-ct ig-margin-s-l ig-margin-m-v">
                            <ig-field-label [label]="nextColorItem.label"
                                            [helpTooltip]="nextColorItem.helpTooltip"></ig-field-label>
                            <ig-color-picker [formControlName]="nextColorItem.controlName"
                                             (colorChange)="onColorChange($event)"
                                             (resetButtonClick)="onResetButtonClick(nextColorItem.controlName)"
                                             [appendTo]="accordion"
                            ></ig-color-picker>
                        </div>
                    }
                </p-accordion-content>
            </p-accordion-panel>
        }
    </p-accordion>
<!--    <div>{{ formGroup.value | json }}</div>-->
</div>