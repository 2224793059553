<div class="dqp-toolbar">
    <p-menu #tertiaryKebabMenu [popup]="true" [model]="tertiaryActions" [appendTo]="'body'"></p-menu>
    <span class="preview-title">{{itemTitle}}</span>
    <span class="ig-grow"></span>

    <!-- primary actions-->
    <ng-container *ngFor="let nextPrimaryAction of primaryActions" >
        <ig-menu-button *ngIf="nextPrimaryAction.items?.length > 0" [label]="nextPrimaryAction.label"
                        [menuItems]="nextPrimaryAction.items"></ig-menu-button>
        <button *ngIf="!(nextPrimaryAction.items?.length)" igButton [label]="nextPrimaryAction.label"
                [escape]="nextPrimaryAction.tooltipOptions?.escape"
                igTooltip="{{nextPrimaryAction.tooltipOptions?.tooltipLabel}}"
                (click)="nextPrimaryAction.command($event)"></button>
    </ng-container>

    <!-- secondary actions-->
    <ng-container *ngFor="let nextSecondaryAction of secondaryActions; index as i" >
        <span *ngIf="i == 0" class="dqp-toolbar-separator"></span>
        <ig-menu-button *ngIf="nextSecondaryAction.items?.length > 0" [label]="nextSecondaryAction.label"
                        [menuItems]="nextSecondaryAction.items"></ig-menu-button>
        <button *ngIf="!(nextSecondaryAction.items?.length)" igButton [label]="nextSecondaryAction.label"
                [escape]="nextSecondaryAction.tooltipOptions?.escape"
                igTooltip="{{nextSecondaryAction.tooltipOptions?.tooltipLabel}}"
                (click)="nextSecondaryAction.command($event)"></button>
    </ng-container>


    <!-- Watchers -->
    <button igButton
            i18n-label label="Watching"
            [badge]="watcherCount > 0 ? watcherCount + '' : ''" (click)="onWatcherButtonClick()"></button>

    <!-- Favorites -->
    <ig-toggle-button *ngIf="favoriteButtonVisible"
                      [igTooltip]="getFavoriteButtonTooltip()"
                      tooltipPosition="top"
                      [onIcon]="'fa fa-star'"
                      [offIcon]="'fa fa-star'"
                      [(ngModel)]="markedAsFavorite"
                      (onChange)="onFavoriteButtonClick($event)"></ig-toggle-button>

    <!-- Pin to Home -->
    <ig-toggle-button *ngIf="pinToHomeButtonVisible"
                      [igTooltip]="getPinToHomeButtonTooltip()"
                      tooltipPosition="top"
                      [onIcon]="'fa fa-home'"
                      [offIcon]="'fa fa-home'"
                      [(ngModel)]="pinnedToHomeScreen"
                      (onChange)="onPinButtonClick($event)"></ig-toggle-button>

    <!-- tertiary actions-->
    <ng-container *ngIf="tertiaryActions?.length > 0">
        <button igButton icon="fa-ellipsis-v" (click)="tertiaryKebabMenu.toggle($event)"></button>
    </ng-container>
</div>