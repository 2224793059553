<div class="dqp-view">
    <p-accordion [multiple]="true">
        <p-accordion-panel>
            <p-accordion-header>
                <span i18n class="p-accordion-header-text"
                      i18n-igTooltip tooltipPosition="right"
                      igTooltip="Permissions the current user has on this pipeline item">Permissions</span>
            </p-accordion-header>
            <p-accordion-content>
                <div class="ig-form-section">
                    <div class="ig-field-v-ct">
                        <dqp-permissions-table [permissionsTableData]="permissionsTableData"></dqp-permissions-table>
                    </div>
                </div>
            </p-accordion-content>
        </p-accordion-panel>
        <p-accordion-panel>
            <p-accordion-header>
                <span i18n class="p-accordion-header-text"
                      i18n-igTooltip tooltipPosition="right"
                      igTooltip="Environment Groups the current user belongs to"
                >Environment Groups</span>
            </p-accordion-header>
            <p-accordion-content>
                <div class="ig-form-section">
                    <div class="ig-field-v-ct">
                        <dqp-env-groups-table [envGroupsTableData]="envGroupsTableData"></dqp-env-groups-table>
                    </div>
                </div>
            </p-accordion-content>
        </p-accordion-panel>
    </p-accordion>
</div>