<div class="ig-form-field ig-w-100pc">
    <label *ngIf="isReadOnly;else notReadOnly">{{getDropDownReadOnlyLabel()}}</label>
    <ng-template #notReadOnly>
        <ng-container *ngIf="!isInAdderRow();else inAdderRow">
            <!--In Regular Row-->
            <p-select
                    [formControl]="getFormControl()"
                    [options]="getAvailableOptions()"
                    filter="true"
                    (onChange)="onDropDownChange($event)"
                    [placeholder]="columnConfig.required ? (''|IgConstants).REQUIRED :''"
                    (onShow)="onDropDownShow()"
                    [styleClass]="'ig-w-100pc ' + (hasFieldRequiredError() ? 'ig-required' : '')">
                >
            </p-select>
        </ng-container>
        <ng-template #inAdderRow>
            <!-- In Adder Row-->
            <p-select
                    [formControl]="getFormControl()"
                    [options]="getAvailableOptions()"
                    filter="true"
                    [placeholder]="getAdderPlaceholder()"
                    (onChange)="onAdderRowDropDownFieldChange($event)"
                    (onShow)="onDropDownShow()"
                    [styleClass]="'ig-w-100pc ' + (hasFieldRequiredError() ? 'ig-required' : '')">
                >
            </p-select>
        </ng-template>
        <ng-container *ngIf="isFieldInvalid()">
            <span class="ig-feedback">{{getFieldErrorMsg()}}</span>
        </ng-container>

    </ng-template>

</div>