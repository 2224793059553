<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Daily Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Number of Days</label>
                <p-input-number [showButtons]="true" formControlName="numberOfDays" min="1" max="365"
                           placeholder="{{(''|IgConstants).REQUIRED}}"
                           [inputStyleClass]="hasFieldRequiredError('numberOfDays') ? 'ig-required': ''">
                </p-input-number>
            </div>
            <div class="ig-field-h-ct no-label-ct ig-margin-m-l">
                <p-checkbox inputId="recurEveryWeekdayCb"
                            [formControl]="recurEveryWeekday"
                            [value]="true"
                ></p-checkbox>
                <label for="recurEveryWeekdayCb" i18n>Every weekday</label>
            </div>
        </div>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'"
                                          [appendTo]="appendTo"
                                          [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>