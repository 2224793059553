import {Component, ElementRef, forwardRef, Inject, inject, ViewChild, ViewEncapsulation} from "@angular/core";
import {TreeComponent} from "./tree.component";
import {PrimeNg17UITreeNode} from "./primeng17-tree";

@Component({
    selector:"ig-tree-node",
    templateUrl: "./tree-node.component.html",
    encapsulation: ViewEncapsulation.None
})
export class TreeNodeComponent extends PrimeNg17UITreeNode {
    @ViewChild("treeNodeLabel")
    public treeNodeLabelElemRef:ElementRef

    tree: TreeComponent = inject(forwardRef(() => TreeComponent));

    constructor(@Inject(forwardRef(() => TreeComponent)) tree) {
        super(tree);
        this.indentation = 1.0;
    }

    /**
     * Overridden to change nodeName for checking.
     *
     * @param event
     */
    onKeyDown(event: KeyboardEvent) {
        const nodeElement = (<HTMLDivElement>event.target).parentElement.parentElement;

        if (nodeElement.nodeName !== 'IG-TREE-NODE') {
            return;
        }
        switch (event.which) {
            //down arrow
            case 40:
                const listElement = (this.tree.droppableNodes) ? nodeElement.children[1].children[1] : nodeElement.children[0].children[1];
                if (listElement && listElement.children.length > 0) {
                    this.focusNode(listElement.children[0]);
                }
                else {
                    const nextNodeElement = nodeElement.nextElementSibling;
                    if (nextNodeElement) {
                        this.focusNode(nextNodeElement);
                    }
                    else {
                        let nextSiblingAncestor = this.findNextSiblingOfAncestor(nodeElement);
                        if (nextSiblingAncestor) {
                            this.focusNode(nextSiblingAncestor);
                        }
                    }
                }

                event.preventDefault();
                break;

            //up arrow
            case 38:
                if (nodeElement.previousElementSibling) {
                    this.focusNode(this.findLastVisibleDescendant(nodeElement.previousElementSibling));
                }
                else {
                    let parentNodeElement = this.getParentNodeElement(nodeElement);
                    if (parentNodeElement) {
                        this.focusNode(parentNodeElement);
                    }
                }

                event.preventDefault();
                break;

            //right arrow
            case 39:
                if (!this.node.expanded && !this.tree.isNodeLeaf(this.node)) {
                    this.expand(event);
                }

                event.preventDefault();
                break;

            //left arrow
            case 37:
                if (this.node.expanded) {
                    this.collapse(event);
                }
                else {
                    let parentNodeElement = this.getParentNodeElement(nodeElement);
                    if (parentNodeElement) {
                        this.focusNode(parentNodeElement);
                    }
                }

                event.preventDefault();
                break;

            //enter
            case 13:
            // space
            case 32:
                this.tree.onNodeClick(event, this.node);
                event.preventDefault();
                break;

            default:
                //no op
                break;
        }
    }

    /**
     * Overridden to change tagName for check.
     *
     * @param nodeElement
     */
    getParentNodeElement(nodeElement) {
        const parentNodeElement = nodeElement.parentElement.parentElement.parentElement;

        return parentNodeElement.tagName === 'IG-TREE-NODE' ? parentNodeElement : null;
    }

}