<div class="ig-row">
    <p-inputGroup>
        <p-colorPicker [disabled]="disabled"
                       [appendTo]="appendTo"
                       (onChange)="onColorSwatchChange($event)"></p-colorPicker>
        <div class="ig-form-field">
            <input pInputText type="text"
                   class="color-picker-input"
                   [value]="value"
                   [disabled]="disabled"
                   i18n-placeholder placeholder="Enter hex color code"
                   (input)="onInputTextChange($event)"
                   (blur)="onTextFieldBlur($event)"
                   maxlength="7"/>
            @if (showInvalidValMsg) {
                <span class="ig-feedback" i18n>Invalid value</span>
            }
        </div>
        <button igButton
                [disabled]="disabled"
                i18n-igTooltip igTooltip="Reset Value"
                tooltipPosition="top"
                tooltipStyleClass="ig-tooltip"
                icon="fa-refresh"
                (click)="onResetButtonClick()"></button>
    </p-inputGroup>
</div>