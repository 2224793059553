<form [formGroup]="formGroup" class="ig-margin-m-t">
<!--<form #form="ngForm" >-->
    <p-table #rolesTable [value]="tableData" [rowHover]="true" [styleClass]="'no-stripe'">
        <ng-template pTemplate="header">
            <tr>
                <th i18n>Role</th>
                <th i18n>Environment Groups</th>
                <th class="col-btn"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr [pSelectableRow]="rowData">
                <td>
                    <div class="ig-form-field ig-w-100pc">
                        <p-select
                                igFormControl
                                [appendTo]="'body'"
                                [formControl]="rowData.permissionIdControl"
                                [options]="availablePermissions"
                                filter="true"
                                i18n-placeholder placeholder="Select..."
                                (onShow)="onPermissionDropDownShow(rowData)"
                                [styleClass]="'ig-w-100pc'">
                        >
                        </p-select>
                    </div>
                </td>
                <td>
<!--                    {{rowData.formGroup.value | json}}-->
                    <ig-multi-select
                            igFormControl
                            [formControl]="rowData.superGroupIdsControl"
                            [options]="availableSuperGroups"
                            maxSelectedLabels="5"
                            optionLabel="groupName"
                            dataKey="id"
                            [styleClass]="'ig-w-100pc'">
                    </ig-multi-select>
                </td>
                <td class="col-btn">
                    <button type="button" class="ig-icon ig-button" (click)="onRowDeleteClick(rowData)">
                        <i class="fa fa-trash"></i>
                    </button>
                </td>
            </tr>

            <ng-container *ngIf="i === (rolesTable.filteredValue || rolesTable.value)?.length - 1">
                <ng-container *ngTemplateOutlet="adderRowTpl"></ng-container>
            </ng-container>
        </ng-template>

        <!-- template displayed when table is empty -->
        <ng-template pTemplate="emptymessage">
            <ng-container *ngTemplateOutlet="adderRowTpl"></ng-container>
        </ng-template>

    </p-table>
<!--    <div>{{formGroup.value | json}}</div>-->
</form>

<!--
   --------------------------------
    template for the `adder` row
   --------------------------------
-->
<ng-template #adderRowTpl>
    <tr>
        <td>
            <div class="ig-form-field ig-w-100pc">
                <p-select
                        igFormControl
                        [appendTo]="'body'"
                        [formControl]="adderPermissionId"
                        [options]="availablePermissions"
                        (onChange)="onAdderRowPermissionFieldChange($event)"
                        (onShow)="onPermissionDropDownShow(adderRow)"
                        filter="true"
                        i18n-placeholder placeholder="Select..."
                        [styleClass]="'ig-w-100pc'"
                >
                </p-select>
            </div>
        </td>
        <td>
<!--            {{adderRow.formGroup.value | json}}-->
            <ig-multi-select
                    [formControl]="adderSuperGroupIds"
                    [options]="availableSuperGroups"
                    (onPanelHide)="onAdderRowSuperGroupMenuHide()"
                    placeholder="{{(''|IgConstants).REQUIRED}}"
                    optionLabel="groupName"
                    dataKey="id"
                    [styleClass]="'ig-w-100pc ' + (adderSuperGroupIds.invalid ? 'ig-required' : '')">
            >
            </ig-multi-select>
        </td>
        <td class="col-btn"></td>
    </tr>
</ng-template>
