<div>
    <section class="ig-dialog-content">
        <form [formGroup]="formGroup">
            <p-tabView [(activeIndex)]="activeIndex">
                <p-tabPanel header="Data Source" [disabled]="isTabDisabled(0)" >
                    <div class="tab-body">
                        <div class="ig-field-v-ct ig-padding-m-t">
                            <label i18n>Select data store which contains profile metrics data</label>
                            <div class="ig-form-field ig-w400 ig-padding-m-t">
                                <p-treeSelect containerStyleClass="w-full"
                                              scrollHeight="270px" required
                                              formControlName="selectedNode" [options]="nodes"
                                              i18n-placeholder placeholder="Select Data Store"
                                              [filter]="true" [filterInputAutoFocus]="true"
                                              (onNodeSelect)="onDataStoreSelect($event)"
                                              (onNodeExpand)="onNodeExpand($event)"
                                >
                                    <ng-template pTemplate="itemtogglericon" let-state>
                                        <i class="pi ig-chevron-down"  *ngIf="state"></i>
                                        <i class="pi ig-chevron-right" *ngIf="!state"></i>
                                    </ng-template>
                                </p-treeSelect>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Strategy" [disabled]="isTabDisabled(1)">
                    <div class="tab-body ig-padding-m">
                        <label i18n>Select strategy for organizing rules</label>
                        <div class="ig-field-v-ct no-label-ct radio-group ig-padding-m-t">
                            <p-radioButton name="strategy" formControlName="strategy" value="oneGroup"
                                           class="ig-margin-s-v ig-margin-xs-t">
                            </p-radioButton>
                            <label i18n>Make rule group spanning all fields</label>
                            <p-radioButton name="strategy" formControlName="strategy" value="groupPerField"
                                           class="ig-margin-s-v">
                            </p-radioButton>
                            <label i18n>Make rule group on a per-field basis</label>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Fields" [disabled]="isTabDisabled(2)">
                    <div class="tab-body ig-padding-m">
                        <label i18n>Select fields to create rules for</label>
                        <div class="ig-padding-m-t">
                            <p-pickList [source]="availableFields" sourceHeader="Available Fields" [sourceStyle]="{ height: '18rem' }"
                                        [target]="selectedFields"  targetHeader="Selected Fields"  [targetStyle]="{ height: '18rem' }"
                                        sourceFilterPlaceholder="Search..." [showSourceControls]="false"
                                        targetFilterPlaceholder="Search..." [showTargetControls]="false"
                                        filterBy="name" required
                            >
                                <ng-template let-field pTemplate="item">
                                    <div class="flex flex-wrap p-2 align-items-center gap-3">
                                        <div class="flex-1 flex flex-column gap-2">
                                            <span class="font-bold">{{ field.name }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-pickList>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Rule Types"  [disabled]="isTabDisabled(3)">
                    <div class="tab-body ig-padding-m">
                        <label i18n>Select types of rules to create for each field</label>
                        <div class="checkbox-group" formGroupName="ruleTypes">
                            <div class="ig-field-v-ct ig-padding-m-t">
                                <p-checkbox formControlName="completeness" [value]="true"></p-checkbox>
                                <label i18n>Completeness</label>
                            </div>
                            <div class="ig-field-v-ct ig-padding-m-t">
                                <p-checkbox formControlName="valueConformity" [value]="true"></p-checkbox>
                                <label i18n>Value Conformity</label>
                            </div>
                            <div class="ig-field-v-ct ig-padding-m-t">
                                <p-checkbox formControlName="regularExpression" [value]="true"></p-checkbox>
                                <label i18n>Regular Expression</label>
                            </div>
<!--                            <div class="ig-field-v-ct ig-padding-m-t">-->
<!--                                <p-checkbox formControlName="typeConformity" [value]="true" i18n-label label="Type Conformity"></p-checkbox>-->
<!--                            </div>-->
                            <div class="ig-field-v-ct ig-padding-m-t">
                                <p-checkbox formControlName="uniqueness" [value]="true" ></p-checkbox>
                                <label i18n>Uniqueness</label>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </form>
    </section>
    <div class="ig-dialog-footer">
            <button igButton icon="fa-arrow-left" [disabled]="!hasPreviousStep()"
                    i18n-label label="Previous"
                    (click)="previousStep()"></button>
            <span class="spacer"></span>
            <button igButton icon="fa-arrow-right" [disabled]="!hasNextStep() || !isCurrentStepComplete()"
                    i18n-label label="Next"
                    (click)="nextStep()"></button>
            <span class="spacer"></span>
            <button igButton (click)="onCancel()" i18n-label label="Cancel"></button>
            <span class="spacer"></span>
            <button igButton class="ig-button-primary" (click)="onFinish()" [disabled]="!isFinishEnabled()" i18n-label label="Finish"></button>
    </div>
</div>

