import {NgModule} from "@angular/core";
import {TableModule} from "primeng/table";
import {TabViewModule} from "primeng/tabview";

import {InputTextModule} from "primeng/inputtext";
import {AccordionModule} from "primeng/accordion";
import {DropdownModule} from "primeng/dropdown";
import {CheckboxModule} from "primeng/checkbox";
import {RadioButtonModule} from "primeng/radiobutton";
import {PasswordModule} from "primeng/password";
import {DialogModule} from "primeng/dialog";
import {MenuModule} from "primeng/menu";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EditDataStoreComponent} from "./edit-data-store.component";
import {ContextMenuModule} from "primeng/contextmenu";
import {
    ButtonModule,
    FieldErrorSummaryModule,
    MultiSelectModule,
    QuickEditTableModule,
    SwitchModule,
    TreeModule,
    UtilModule
} from "@infogix/angular-ui-framework";
import {DataStoreDetailsEditTabComponent} from "./details/data-store-details-edit-tab.component";
import {EditDataStoreRoutingModule} from "./edit-data-store-routing.module";
import {FileLayoutEditComponent} from "./details/file-layout-edit.component";
import {FieldPanelEditComponent} from "./fields/field-panel-edit.component";
import {Textarea} from "primeng/inputtextarea";
import {ComplexLayoutEditComponent} from "./details/complex-layout-edit.component";
import {NameValuePopupComponent} from "../details/name-value-popup.component";
import {NameValueTableComponent} from "../details/name-value-table.component";
import {S3ChannelEditComponent} from "./details/s3-channel-edit.component";
import {KafkaChannelEditComponent} from "./details/kafka-channel-edit.component";
import {HdfsChannelEditComponent} from "./details/hdfs-channel-edit.component";
import {FileSystemChannelEditComponent} from "./details/file-system-channel-edit.component";
import {DatabaseChannelEditComponent} from "./details/database-channel-edit.component";
import {SqlResultPopupComponent} from "./details/sql-result-popup.component";
import {SqlResultTableComponent} from "./details/sql-result-table.component";
import {SynonymTableComponent} from "./fields/synonym-table.component";
import {ConfirmDependencyPopupComponent} from "./confirm-dependency-popup.component";
import {MetadataManagementModule} from "@cafe/metadata-management";
import {IndexFieldsTableComponent} from "./indexes/index-fields-table.component"
import {IndexesPanelEditComponent} from "./indexes/indexes-panel-edit.component";
import {InputNumber} from "primeng/inputnumber";
import {Select} from "primeng/select";


@NgModule({
    imports: [
        EditDataStoreRoutingModule,
        TableModule,
        TabViewModule,
        ButtonModule,
        FieldErrorSummaryModule,
        MultiSelectModule,
        CommonModule,
        InputTextModule,
        Textarea,
        AccordionModule,
        DropdownModule,
        ReactiveFormsModule,
        FormsModule,
        CheckboxModule,
        RadioButtonModule,
        DialogModule,
        MenuModule,
        MetadataManagementModule,
        TreeModule,
        QuickEditTableModule,
        ContextMenuModule,
        SwitchModule,
        PasswordModule,
        UtilModule,
        FieldErrorSummaryModule,
        InputNumber,
        Select
    ],
    declarations: [
        EditDataStoreComponent,
        DataStoreDetailsEditTabComponent,
        FileLayoutEditComponent,
        FieldPanelEditComponent,
        ComplexLayoutEditComponent,
        NameValuePopupComponent,
        NameValueTableComponent,
        S3ChannelEditComponent,
        KafkaChannelEditComponent,
        HdfsChannelEditComponent,
        FileSystemChannelEditComponent,
        DatabaseChannelEditComponent,
        SqlResultPopupComponent,
        SqlResultTableComponent,
        SynonymTableComponent,
        ConfirmDependencyPopupComponent,
        IndexFieldsTableComponent,
        IndexesPanelEditComponent
    ]
})

export class EditDataStoreModule {

}