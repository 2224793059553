<div class="ig-paginator"  [class.ig-paginator-links-newline]="showPageLinksOnNewLine">
    <div [class]="styleClass" [ngStyle]="style" [ngClass]="'p-paginator p-component p-component-header p-unselectable-text p-helper-clearfix'">
        <div class="ig-paginator-top">
            <span class="p-paginator-current" *ngIf="(pageLinks && pageLinks.length > 1)">{{currentPageReport}}</span>
            <span class="p-paginator-current" *ngIf="(!pageLinks || pageLinks.length <= 1)">
                <ng-container i18n>{{(totalRecords == null ? 0 : totalRecords)}} {totalRecords, plural, =1 {item} other {items}}</ng-container>
            </span>

            <span *ngIf="showPageLinksOnNewLine" style="flex-grow: 1;"></span>
            <ng-container *ngIf="!showPageLinksOnNewLine && (pageLinks && pageLinks.length > 1)">
                <ng-container *ngTemplateOutlet="pageLinksTpl"></ng-container>
            </ng-container>
            <p-select [options]="rowsPerPageItems" [(ngModel)]="rows" *ngIf="rowsPerPageOptions"
                        (onChange)="onRppChange($event)" appendTo="body" [scrollHeight]="dropdownScrollHeight">
            </p-select>
        </div>
        <ng-container *ngIf="showPageLinksOnNewLine && (pageLinks && pageLinks.length > 1)">
            <ng-container *ngTemplateOutlet="pageLinksTpl"></ng-container>
        </ng-container>
        <!-- page links template -->
        <ng-template #pageLinksTpl>
            <div class="ig-paginator-links">
                <a [attr.tabindex]="isFirstPage() ? null : '0'" class="p-paginator-first p-paginator-element p-state-default p-corner-all"
                   (click)="changePageToFirst($event)" (keydown.enter)="changePageToFirst($event)" [ngClass]="{'p-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
                    <span class="p-paginator-icon pi pi-step-backward"></span>
                </a>
                <a tabindex="0" [attr.tabindex]="isFirstPage() ? null : '0'" class="p-paginator-prev p-paginator-element p-state-default p-corner-all"
                   (click)="changePageToPrev($event)" (keydown.enter)="changePageToPrev($event)" [ngClass]="{'p-disabled':isFirstPage()}" [tabindex]="isFirstPage() ? -1 : null">
                    <span class="p-paginator-icon pi pi-caret-left"></span>
                </a>
                <span class="p-paginator-pages">
                    <a tabindex="0" *ngFor="let pageLink of pageLinks" class="p-paginator-page p-paginator-element p-state-default p-corner-all"
                       (click)="onPageLinkClick($event, pageLink - 1)" (keydown.enter)="onPageLinkClick($event, pageLink - 1)" [ngClass]="{'p-state-active': (pageLink-1 == getPage())}">{{pageLink}}</a>
                </span>
                <a [attr.tabindex]="isLastPage() ? null : '0'" class="p-paginator-next p-paginator-element p-state-default p-corner-all"
                   (click)="changePageToNext($event)" (keydown.enter)="changePageToNext($event)" [ngClass]="{'p-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
                    <span class="p-paginator-icon pi pi-caret-right"></span>
                </a>
                <a [attr.tabindex]="isLastPage() ? null : '0'" class="p-paginator-last p-paginator-element p-state-default p-corner-all"
                   (click)="changePageToLast($event)" (keydown.enter)="changePageToLast($event)" [ngClass]="{'p-disabled':isLastPage()}" [tabindex]="isLastPage() ? -1 : null">
                    <span class="p-paginator-icon pi pi-step-forward"></span>
                </a>
            </div>
        </ng-template>
    </div>
</div>