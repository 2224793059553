<div class="tenant-theme-toolbar p-col-12 dqp-toolbar ig-margin-m-v">
    <span class="ig-grow"></span>
    <input #themeFileInput type="file" accept=".json"
           (change)="onFileInputChange($event)" />
    <button igButton
            i18n-igTooltip igTooltip="Upload a theme file"
            i18n-label label="Import"
            (click)="onImportButtonClick()"
    ></button>
    <button igButton
            i18n-igTooltip igTooltip="Download theme to a file"
            i18n-label label="Export"
            (click)="onExportButtonClick()"
    ></button>
    <span class="link-button-separator"></span>
    <a (click)="setTabsExpanded(false)" class="link-button" i18n>Collapse All</a>
    <span class="link-button-separator"></span>
    <a (click)="setTabsExpanded(true)" class="link-button" i18n>Expand All</a>
</div>
<p-accordion [formGroup]="formGroup" class="ig-padding-s-t" [multiple]="true" [value]="['headerBar', 'colorScheme']">

    <!--
        -----------------------------------------
        Header Bar
        -----------------------------------------
        -->
    <p-accordion-panel value="headerBar">
        <p-accordion-header>
            <span i18n class="p-accordion-header-text">Header Bar</span>
            <ig-field-error-summary [formModel]="getHeaderBarFormGroup()"></ig-field-error-summary>
        </p-accordion-header>
        <p-accordion-content>
            <dqp-header-bar-theme [settings]="settings"></dqp-header-bar-theme>
        </p-accordion-content>
    </p-accordion-panel>
    <!--
        -----------------------------------------
        Colors
        -----------------------------------------
        -->
    <p-accordion-panel value="colorScheme" class="inner-accordion-tab">
        <p-accordion-header>
            <span i18n class="p-accordion-header-text">Color Scheme</span>
            <ig-field-error-summary class="ig-margin-s-l" [formModel]="getColorsFormGroup()"></ig-field-error-summary>
        </p-accordion-header>
        <p-accordion-content>
            <dqp-colors-theme [settings]="settings"></dqp-colors-theme>
        </p-accordion-content>
    </p-accordion-panel>
</p-accordion>
<!--<div>{{formGroup.value | json}}</div>-->