<p-select
        [options]="availableOptions"
        [autoDisplayFirst]="false"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [styleClass]="styleClass"
        [style]="style"
        [disabled]="disabled"
        [appendTo]="appendTo"
        (onChange)="onDropDownChange($event)"
        (onFocus)="focus.emit($event)"
        (onBlur)="onDropDownBlur($event)"
        (onClick)="click.emit($event)"
        (onShow)="show.emit($event)"
        (onHide)="hide.emit($event)"
        [showClear]="true"
        [filter]="true"
        [group]="true"
>
    <ng-template let-group pTemplate="group">
        <div (click)="$event.stopPropagation()">
            <span>{{group.label}}</span>
        </div>
    </ng-template>
    <ng-template let-selectedItem pTemplate="selectedItem">
        <div><i [ngClass]="'icon-img fa fa-' + selectedItem.value"></i> {{selectedItem.label}}<span *ngIf="selectedItem.value === defaultValue" i18n> (default icon)</span></div>
    </ng-template>
    <ng-template let-item pTemplate="item">
        <div><i [ngClass]="'icon-img fa fa-' + item.value"></i> {{item.label}}</div>
    </ng-template>

</p-select>