<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Weekly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Recur every</label>
                <p-input-number [showButtons]="true"
                                formControlName="recurEveryWeek" min="1" max="52"
                                placeholder="{{(''|IgConstants).REQUIRED}}"
                                [inputStyleClass]="hasFieldRequiredError('recurEveryWeek') ? 'ig-required': ''">
                </p-input-number>
            </div>
        </div>
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Select day</label>
                <div class="checkbox-group">
                    <p-checkbox inputId="sundayCb" [formControl]="recurDays" [value]="'sunday'"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="sundayCb" i18n>Sunday</label>

                    <p-checkbox inputId="mondayCb" [formControl]="recurDays" [value]="'monday'"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="mondayCb" i18n>Monday</label>

                    <p-checkbox inputId="tuesdayCb" [formControl]="recurDays" [value]="'tuesday'"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="tuesdayCb" i18n>Tuesday</label>

                    <p-checkbox inputId="wednesdayCb" [formControl]="recurDays" [value]="'wednesday'"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="wednesdayCb" i18n>Wednesday</label>

                    <p-checkbox inputId="thursdayCb" [formControl]="recurDays" [value]="'thursday'"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="thursdayCb" i18n>Thursday</label>

                    <p-checkbox inputId="fridayCb" [formControl]="recurDays" [value]="'friday'"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="fridayCb" i18n>Friday</label>

                    <p-checkbox inputId="saturdayCb" [formControl]="recurDays" [value]="'saturday'" i18n-label label="Saturday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <label for="saturdayCb" i18n>Saturday</label>
                </div>
            </div>
        </div>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'"
                                          [appendTo]="appendTo"
                                          [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>