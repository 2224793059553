<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Hourly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Recur every</label>
                <p-input-number [showButtons]="true"
                                formControlName="recurEveryMinHour" [min]="minsOrHoursMin" [max]="minsOrHoursMax"
                                [inputStyleClass]="recurEveryMinHourStyleClass"
                                placeholder="{{(''|IgConstants).REQUIRED}}"
                >
                </p-input-number>
                <ng-container *ngIf="isFieldInvalid('recurEveryMinHour')">
                    <span *ngIf="recurEveryMinHour.errors.min" class="ig-feedback" i18n>
                            Value must be greater than {{recurEveryMinHour.errors.min?.min}}
                        </span>
                    <span *ngIf="recurEveryMinHour.errors.max" class="ig-feedback" i18n>
                            Value must be smaller than {{recurEveryMinHour.errors.max?.max}}
                        </span>
                </ng-container>
            </div>
            <div class="ig-field-h-ct no-label-ct radio-group">
                <p-radioButton inputId="hoursRadio" name="minOrHours" formControlName="minOrHours" value="HRS"></p-radioButton>
                <label for="hoursRadio" i18n>Hours</label>
                <p-radioButton inputId="minsRadio" name="minOrHours" formControlName="minOrHours" value="MINS"></p-radioButton>
                <label for="minsRadio" i18n>Minutes</label>
            </div>
        </div>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'"
                                          [appendTo]="appendTo"
                                          [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>